import React from 'react'
import { connect } from "react-redux";
import { FaPlay, FaPause, FaStepBackward, FaStepForward } from 'react-icons/fa';
import { IconContext } from "react-icons";
import { playAction, pauseAction, visibleDate } from "../actions";
import { addDays, subDays, addMonths, subMonths, addYears, subYears } from 'date-fns'

import './TimeSlider.css';


class ControlButtons extends React.Component {


    playButtonHandler() {
        this.props.dispatch(this.props.play ? pauseAction() : playAction())
    }

    backwardButtonHandler() {
        const unit = this.props.timeseries.interval.unit
        const value = this.props.timeseries.interval.value
        const date = this.props.visibleDate
        const startDate = new Date(this.props.timeseries.startDate)
        const endDate = new Date(this.props.timeseries.endDate)

        let result
    
        if (unit === 'year') {
            result = subYears(date, value)
        } else if (unit === 'month') {
            result = subMonths(date, value)
        } else {
            result = subDays(date, value)
        }

        if (result < startDate) {
            result = endDate
        }

        this.props.dispatch(visibleDate(result))
    }

    forwardButtonHandler() {
        const unit = this.props.timeseries.interval.unit
        const value = this.props.timeseries.interval.value
        const date = this.props.visibleDate
        const startDate = new Date(this.props.timeseries.startDate)
        const endDate = new Date(this.props.timeseries.endDate)

        let result
    
        if (unit === 'year') {
            result = addYears(date, value)
        } else if (unit === 'month') {
            result = addMonths(date, value)
        } else {
            result = addDays(date, value)
        }

        if (result > endDate) {
            result = startDate
        }

        this.props.dispatch(visibleDate(result))
    }


    
    render() {
        return (
            <div className='controlPanel'>
                <button onClick={() => this.backwardButtonHandler()} type="button" className='controlButtons'>
                    <IconContext.Provider value={{ className: 'controlButtonIcon'}}>
                        <FaStepBackward />
                    </IconContext.Provider>
                </button>
                <button onClick={() => this.playButtonHandler()} type="button" className='controlButtons'>
                    <IconContext.Provider value={{ className: 'controlButtonIcon'}}>
                        {
                            this.props.play ? <FaPause /> : <FaPlay />
                        }
                    </IconContext.Provider>
                </button>
                <button onClick={() => this.forwardButtonHandler()} type="button" className='controlButtons'>
                    <IconContext.Provider value={{ className: 'controlButtonIcon'}}>
                        <FaStepForward />
                    </IconContext.Provider>
                </button>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
      play: state.play,
      layer: state.layer,
      layerDescription: state.layerDescription,
      timeseries: state.timeseries,
      visibleDate: state.visibleDate
    }
  }
  
  export default connect(mapStateToProps)(ControlButtons)
