import React from 'react'
import { connect } from "react-redux";
import { layerSelected } from "../actions";
import { IconContext } from "react-icons";
import { FaInfoCircle } from 'react-icons/fa';
import ReactTooltip from "react-tooltip";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import './LayerSelector.css';

class LayerSelector extends React.Component {

 
    render() {
        return (
            <div>
                <div className='layerSelector'>
                    <Select
                        disableUnderline={true}
                        value={this.props.layer}
                        onChange={(event) => this.props.dispatch(layerSelected(event.target.value))}
                    >
                        <MenuItem value={'P_NFSY'}>Year</MenuItem>
                        <MenuItem value={'P_NFSM'}>Month</MenuItem>
                        <MenuItem value={'P_NFSD'}>Day</MenuItem>
                    </Select>
                </div>
                <div>
                    <button type="button" data-tip data-for="registerTip" className='layerDescriptionButton'>
                        <IconContext.Provider value={{ color: 'white', size: '13px' }}>
                            <FaInfoCircle /> 
                        </IconContext.Provider>
                    </button>
                    <ReactTooltip id="registerTip" place="right" data-event="click focus" effect="solid">
                        {this.props.layerDescription ? this.props.layerDescription.licenseInformation.description : ""}
                    </ReactTooltip>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        layerDescription: state.layerDescription,
        layer: state.layer
    }
}

export default connect(mapStateToProps)(LayerSelector)