import { addMilliseconds, subMilliseconds, format } from 'date-fns'
import React from 'react'
import { DatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import { visibleDate } from '../actions';
import "./TimeSlider.css"

class TimeSelector extends React.Component {

    datePickerViews = ["year", "month"]
    minDate = new Date("2000-01-01")
    maxDate = new Date("2020-12-31")

    handleChange = date => {
        this.props.dispatch(visibleDate(date))
    }

    formatLabel = (date, invalidLabel) => {

        var dateFormat

        if (this.props.layer === 'P_NFSY') {
            dateFormat = 'yyyy'
        } else if (this.props.layer === 'P_NFSD') {
            dateFormat = 'yyyy/MM/dd'
        } else {
            dateFormat = 'yyyy/MM'        }

        return format(date, dateFormat)
    }

    calculateDatePickerViews() {
        if (this.props.layer === 'P_NFSY') {
            this.datePickerViews = ["year"]
        } else if (this.props.layer === 'P_NFSM') {
            this.datePickerViews = ["year", "month"]
        } else {
            this.datePickerViews = ["year", "month", "date"]
        }
    }

    calculateMinMaxDates() {
        if (!this.props.timeseries) {
            return
        }

        this.minDate = new Date(this.props.timeseries.startDate)
        this.maxDate = new Date(this.props.timeseries.endDate)

        if (this.minDate >= this.props.visibleDate) {
            //workaround - if date is equal than warning message is displayed
            const newVisibleDate = addMilliseconds(this.minDate, 1)
            this.props.dispatch(visibleDate(newVisibleDate))
        }

        if (this.maxDate < this.props.visibleDate) {
            //workaround - if date is equal than warning message is displayed
            const newVisibleDate = subMilliseconds(this.minDate, 1)
            this.props.dispatch(visibleDate(newVisibleDate))
        }

    }

    render() {
        return (
            <div className="datePicker">
                <DatePicker
                    views={this.datePickerViews}
                    minDate={this.minDate}
                    maxDate={this.maxDate}
                    onChange={this.handleChange}
                    disabled={this.props.play}
                    autoOk={true}
                    value={this.props.visibleDate}
                    labelFunc={this.formatLabel}
                    InputProps={{ disableUnderline: true }}
                />
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.layer !== this.props.layer) {
            this.calculateDatePickerViews()
        }

        if (prevProps.timeseries !== this.props.timeseries) {
            this.calculateMinMaxDates()
        }

    }
}


const mapStateToProps = (state) => {
    return {
      play: state.play,
      layer: state.layer,
      layerDescription: state.layerDescription,
      timeseries: state.timeseries,
      visibleDate: state.visibleDate
    }
  }
  
export default connect(mapStateToProps)(TimeSelector)
