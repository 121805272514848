import React from 'react'
import mapboxgl from 'mapbox-gl';
import { connect } from "react-redux";
import { layerSelected, fetchLayerDesctiption, visibleDate } from "../actions";
import { format, parse, isSameDay, isSameMonth, isSameYear, addDays, addMonths, addYears } from 'date-fns'
import { convertToTimeZone } from 'date-fns-timezone'
import './Map.css'

mapboxgl.accessToken = 'pk.eyJ1IjoiamF3b3Jza2l3b2oiLCJhIjoiY2pldG1zYjBwMDI2YTJ4bnpnbjBrYTJ6bSJ9.DXTExrussJkU_JxtpLPSzg';


class Map extends React.Component {
  timestamps = null
  url = null
  layers = {};
  curTimestampIndex = 0;
  cacheForward = 2;
  cacheBackward = 1;
  map = null
  interval = null
    
  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      // style: 'mapbox://styles/mapbox/satellite-v9',
      center: [19, 50],
      zoom: 4,
      attributionControl: false,
      logoPosition: 'top-left',
    });
    this.map.addControl(new mapboxgl.AttributionControl({ compact: true }), 'top-right');

    this.map.on('load', () => {
      this.props.dispatch(layerSelected('P_NFSY'));
    });
  }

  render() {
    return (
      <div>
        <div ref={el => this.mapContainer = el} className='mapContainer' />
      </div>
    )
  }

  selectLayer(timeSeriesDescription) {

    if (this.timestamps) {
      const timestamp = this.timestamps[this.curTimestampIndex];
      this.removeLayer(timestamp);
    }

    this.timestamps = this.calculateTimestamps(timeSeriesDescription);
    this.url = this.calculateUrl(timeSeriesDescription);
  
    this.curTimestampIndex = 0
    const newTimestamp = this.timestamps[this.curTimestampIndex];

    this.addLayer(newTimestamp);
    this.map.setPaintProperty(newTimestamp, 'fill-opacity', 0.5);
  }

  calculateTimestamps(timeSeriesDescription) {

    let result = []

    const startDate = new Date(timeSeriesDescription.startDate)
    const endDate = new Date(timeSeriesDescription.endDate)

    let currentDate = convertToTimeZone(startDate, { timeZone: 'UTC' })

    do {
      result.push(format(currentDate, "yyyyMMdd'T'HHmm'Z'"))
      currentDate = this.addTime(currentDate)
    } while (currentDate.getTime() < endDate.getTime())

    return result
  }

  addTime(date) {
    const unit = this.props.timeseries.interval.unit
    const value = this.props.timeseries.interval.value

    let result

    if (unit === 'year') {
      result = addYears(date, value)
    } else if (unit === 'month') {
      result = addMonths(date, value)
    } else {
      result = addDays(date, value)
    }

    return result
  }

  calculateUrl(timeSeriesDescription) {
    return timeSeriesDescription.url;
  }

  addLayer(timestamp) {
    if (this.layers[timestamp]) {
      return;
    }
  
    // eslint-disable-next-line
    const url = this.url.replace('${timestamp}', timestamp);

    const layerDescription = this.props.layerDescription

    const vectorLayer = {
        'id': timestamp.toString(),
        'type': 'fill',
        'source': {
            "type": "vector",
            tiles: [url],
            "minzoom": layerDescription.minZoom,
            "maxzoom": layerDescription.maxZoom
        },
        "source-layer": "vector",
        'paint': {
            'fill-opacity': 0.0,
            'fill-color': layerDescription.style.paint['fill-color'],
            // 'fill-color': ['get', 'fill-color'],
        },
    };
    this.map.addLayer(vectorLayer);
  
    this.layers[timestamp] = vectorLayer;
  };
  
  removeLayer(timestamp) {
    if (!this.layers[timestamp]) {
      return;
    }
    this.map.removeLayer(timestamp);
    this.map.removeSource(timestamp);
    delete this.layers[timestamp];
  };

  nextTimestamp() {
    if (this.curTimestampIndex > this.timestamps.length - 1) {
      this.curTimestampIndex = -1;
    }
    this.curTimestampIndex++;

    for (let i = 0; i < this.timestamps.length; i++) {
      const timestamp = this.timestamps[i];
      const backwardIndex = Math.max(this.curTimestampIndex - this.cacheBackward, 0);
      const forwardIndex = Math.min(this.curTimestampIndex + this.cacheForward, this.timestamps.length - 1);

      if (i < backwardIndex || i > forwardIndex) {
        this.removeLayer(timestamp);
        continue;
      }

      if (i >= backwardIndex || i <= forwardIndex) {
        this.addLayer(timestamp);
      }

      if (i === this.curTimestampIndex) {
        let timestampDate = parse(timestamp.replace('T', '').replace('Z', ''), 'yyyyMMddHHmm', new Date())
        this.props.dispatch(visibleDate(timestampDate))

        this.map.setPaintProperty(timestamp, 'fill-opacity', 0.5);
      } else {
        this.map.setPaintProperty(timestamp, 'fill-opacity', 0.0);
      }
    }
  }

  selectTimestamp(date) {
    let timestampIndex = this.findTimestamp(date)
    if (timestampIndex !== -1) {
      const oldTimestamp = this.timestamps[this.curTimestampIndex];
      this.removeLayer(oldTimestamp);

      this.curTimestampIndex = timestampIndex

      const newTimestamp = this.timestamps[this.curTimestampIndex];

      this.addLayer(newTimestamp);
      this.map.setPaintProperty(newTimestamp, 'fill-opacity', 0.5);
    }

  }

  findTimestamp(date) {
    for (const [i, value] of this.timestamps.entries()) {
      let timestamp = parse(value.replace('T', '').replace('Z', ''), 'yyyyMMddHHmm', new Date())
      if ((this.props.layer === 'P_NFSY' && isSameYear(timestamp, date))
        || (this.props.layer === 'P_NFSM' && isSameMonth(timestamp, date))
        || (this.props.layer === 'P_NFSD' && isSameDay(timestamp, date))) {

        return i
      }
    }

    return -1
  }

  toggleAnimation() {
    if (!this.map) {
      return
    }

    if (this.props.play) {
      this.interval = setInterval(this.nextTimestamp.bind(this), 5000)
    }

    if (!this.props.play) {
      clearInterval(this.interval)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    if (prevProps.play !== this.props.play) {
      this.toggleAnimation()
    }
    
    if (prevProps.layer !== this.props.layer) {
      this.fetchMetadata(this.props.layer)
    }

    if (prevProps.timeseries !== this.props.timeseries) {
      this.selectLayer(this.props.timeseries)
    }

    if (prevProps.visibleDate !== this.props.visibleDate) {
      this.selectTimestamp(this.props.visibleDate)
    }
  }

  fetchMetadata(layer) {
    this.props.dispatch(fetchLayerDesctiption(layer));
  }


}

const mapStateToProps = (state) => {
  return {
    play: state.play,
    layer: state.layer,
    layerDescription: state.layerDescription,
    timeseries: state.timeseries,
    visibleDate: state.visibleDate
  }
}

export default connect(mapStateToProps)(Map)