import React from 'react'
import LayerSelector from './layerSelector/LayerSelector'
import Legend from './legend/Legend'
import Map from './map/Map'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import TimeSlider from './timeslider/TimeSlider'

export default function App() {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
                <Map />
                <TimeSlider />
                <Legend />
                <LayerSelector />
            </div>
        </MuiPickersUtilsProvider>
    )
}
