import { combineReducers } from 'redux';
import layerDescriptionLoadedReducer from './layerDescriptionLoaded';
import layerSelectedReducer from './layerSelected';
import playPauseReducer from './playPause';
import timeseriesLoadedReducer from './timeSeriesLoaded';
import visibleDateReducer from './visibleDate';

const allReducers = combineReducers( {
    play: playPauseReducer,
    layer: layerSelectedReducer,
    layerDescription: layerDescriptionLoadedReducer,
    timeseries: timeseriesLoadedReducer,
    visibleDate: visibleDateReducer
})

export default allReducers;