import axios from "axios";

export const playAction = () => {
    return {
      type: 'PLAY'
    }
  }
  
export const pauseAction = () => {
    return {
      type: 'PAUSE'
    }
  }  

export const layerSelected = (layer) => {
    return {
      type: 'LAYER_SELECTED',
      payload: layer
    }
  }    

export const layerDescriptionLoaded = (layerDescription) => {
    return {
      type: 'LAYER_DESCRIPTION_LOADED',
      payload: layerDescription
    }
  }    

export const fetchLayerDesctiption = (layer) => {
    return (dispatch) => {
      axios.get('https://metadata.rainhistory.com/description/' + layer)
      .then(res => {
        dispatch(fetchTimeseries(layer))
        dispatch(layerDescriptionLoaded(res.data))
      })
    }
  }      

export const timeSeriesLoaded = (timeseries) => {
    return {
      type: 'TIME_SERIES_LOADED',
      payload: timeseries
    }
  }    

export const fetchTimeseries = (layer) => {
    return (dispatch) => {
      axios.get('https://metadata.rainhistory.com/timeseries/' + layer)
      .then(res => {
        dispatch(timeSeriesLoaded(res.data))
      })
    }
  }        

export const visibleDate = (date) => {
    return {
      type: 'DATE_CHANGED',
      payload: date
    }
  }    
